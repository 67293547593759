@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  font-family: "Cairo";
}

html {
  scroll-behavior: smooth;
  @apply z-0 bg-light;
}
